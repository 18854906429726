import { httpGet, httpPost } from "@/api/request";
import { useStoreUser } from "@/store";

// 获取报警列表
export function GET_ALARM_LIST(params: object) {
    return httpPost("/pole/alarm/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 删除报警
export function DELETE_ALARM(params: object) {
    return httpPost("/pole/alarm/delete", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 处理报警
export function HANDLE_ALARM(params: object) {
    return httpPost("/pole/alarm/changeStatus", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
